<template>
  <!-- TODO: Implement pagination -->
  <b-table
    v-if="tableItems && tableFields"
    :thead-class="tableHeader"
    :fields="tableFields"
    :items="tableItems"
    borderless
    responsive
    hover
    striped
  >
    <template #head()="data">
      <span>{{ data.label }}</span>
    </template>
    <template #cell(name)="row">
      <div class="flex-shrink-0 flex-grow-0 thumbnail m-auto">
        <img :src="imageUrl(row.item.path.image.path)" />
      </div>
      <b v-if="row.item.path.name" class="ml-3 flex-grow-1">{{
        row.item.path.name
      }}</b>
    </template>
    <template #cell(created_at)="row">
      <span v-if="row.item.createdAt">{{
        formatDate(new Date(row.item.createdAt))
      }}</span>
    </template>
    <template #cell(download)="row" size="sm">
      <b-button
        class="download-btn inline-block"
        @click="emitItemToDownload(row.item)"
        >Stiahnuť</b-button
      >
    </template>
  </b-table>
</template>

<script>
import { formatInTimeZone } from "date-fns-tz";
import { mapGetters } from "vuex";
import { resolveCmsMediaUrl } from "/utils/helpers";

export default {
  props: {
    tableItems: {
      required: true,
      type: Array,
    },
    tableFields: {
      required: true,
      type: Array,
    },
    tableHeader: {
      required: false,
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    emitItemToDownload(certificate) {
      this.$emit("itemToDownload", certificate);
    },
    formatDate(date) {
      return formatInTimeZone(date, "Europe/Bratislava", "dd.MM.yyyy");
    },
    imageUrl(path) {
      return resolveCmsMediaUrl(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  height: 48px;
  width: 76px;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 76px;
    object-fit: cover;
  }
}

svg {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #86b2cd;
  font-weight: lighter;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    color: white;
  }
}

::v-deep table {
  overflow-y: hidden;
  overflow-x: hidden;
  thead {
    tr {
      border-bottom: 1px solid #cadeea;
      th {
        vertical-align: middle;
        span {
          color: #86b2cd;
          font-weight: lighter;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        color: #2b2e4a;
        button {
          color: #86b2cd !important;
          border-color: #86b2cd !important;
          font-size: small;
          font-weight: bold;
          min-width: 136px;
          min-height: 39px;
          padding: 0;
          &:hover {
            background-color: #fb2564;
            color: #ffffff !important;
            border-color: #fb2564 !important;
          }
        }
        a {
          text-decoration: none;
          color: #2b2e4a;
          font-weight: bold;
        }
      }
    }
  }
}

// TODO: Better responsiveness
::v-deep td.name {
  display: flex;
  align-items: center;
}

::v-deep .created-at {
  width: 20%;
}

::v-deep .download {
  width: 20%;
}

// TODO: Fix button colors
::v-deep .download-btn {
  width: 136px;
  background-color: #2b2e4a;
  color: white !important;
  border-radius: 100px;
  border-color: #2b2e4a !important;
}

button {
  &:hover {
    background-color: #fb2564;
    color: #ffffff !important;
    border-color: #fb2564 !important;
  }
}
</style>
